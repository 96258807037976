<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <v-card>
            <v-card-text class="pa-5">
              <h3 class="title text--darken-2 font-weight-regular">
                {{ $t("card.application-setting") }}
              </h3>
              <h6 class="subtitle-2 font-weight-light mb-2">
                {{ $t("label.customize-global-app-setting-and-behavior") }}
              </h6>

              <v-row>
                <v-col cols="12" md="6" sm="12" class="pa-4">
                  <v-list
                    two-line
                    class="rounded border--thin height--100-percent"
                  >
                    <v-skeleton-loader
                      class="mx-auto"
                      max-width="100%"
                      type="article, actions"
                      v-if="axiosLoading"
                    ></v-skeleton-loader>

                    <v-row v-if="!axiosLoading">
                      <v-col cols="6">
                        <v-subheader class="text-uppercase text--bigger">{{
                          $t("label.application-administrator")
                        }}</v-subheader>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              v-on="on"
                              v-bind="attrs"
                              small
                              dark
                              class="mr-2"
                              color="primary"
                              @click="setAdminDialog(true)"
                            >
                              <v-icon>{{ iconPlus }}</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("tooltip.add-new-admin") }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>

                    <div
                      v-if="adminList && adminList.length > 0 && !axiosLoading"
                      class="table-responsive-container scroll__y-only"
                      style="max-height: 400px"
                    >
                      <div class="table-responsive">
                        <template v-for="(item, idx) in adminList">
                          <v-hover v-slot="{ hover }" v-bind:key="idx">
                            <v-list-item
                              :key="item.nik"
                              :class="{ 'on-hover': hover }"
                              class="v-list__admin"
                            >
                              <template v-slot:default="{ active }">
                                <v-list-item-avatar>
                                  <v-img :src="item.avatar"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ item.nama }}
                                    <v-chip
                                      v-show="item.role === 'superadmin'"
                                      class="theme-bg4"
                                      dark
                                      small
                                    >
                                      {{ $t("badge.super-administrator") }}
                                    </v-chip>
                                    <v-chip
                                      v-show="item.role === 'admin'"
                                      class="theme-bg"
                                      dark
                                      small
                                    >
                                      {{ $t("badge.administrator") }}
                                    </v-chip>
                                  </v-list-item-title>
                                  <v-list-item-subtitle
                                    class="font-size-smaller"
                                    v-html="
                                      item.bagian_name ? item.bagian_name +'-'+ item.unit_name : item.unit_name
                                    "
                                  ></v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action
                                  v-on:click.self="
                                    showRevisionDetail(item.id_hash)
                                  "
                                >
                                  <div
                                    class="button__list-container admin__action left-40pcn"
                                    v-show="hover"
                                    v-bind:class="{ 'on-hover': active }"
                                  >
                                    <v-tooltip bottom>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          v-on="on"
                                          v-bind="attrs"
                                          class="mr-8"
                                          fab
                                          small
                                          dark
                                          color="red darken-1"
                                          v-on:click="deleteAdmin(item.id_hash, item.nik, item.nama)"
                                        >
                                          <v-icon dark>
                                            {{ iconDelete }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{
                                        $t("tooltip.delete-administrator")
                                      }}</span>
                                    </v-tooltip>
                                  </div>
                                </v-list-item-action>
                              </template>
                            </v-list-item>
                          </v-hover>
                        </template>
                      </div>
                    </div>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pa-4">
                  <v-list
                    two-line
                    class="rounded border--thin height--100-percent"
                  >
                    <v-subheader class="text-uppercase text--bigger">{{
                      $t("label.avatar-in-data-list")
                    }}</v-subheader>

                    <v-card-text class="pt-0">
                      <div class="avoore-checkbox--container">
                        <div
                          class="avoore-checkbox--responsive"
                          v-for="item in items"
                          :key="item.id"
                        >
                          <label
                            class="avoore-checkbox path"
                            v-bind:class="{
                              'theme--dark': $vuetify.theme.dark,
                            }"
                          >
                            <input
                              type="checkbox"
                              class="d-inline-flex"
                              @change="changeMeInDb"
                              :value="item.id"
                              :id="item.id"
                              :checked="item.checked"
                            />
                            <svg viewBox="0 0 21 21">
                              <path
                                d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                              ></path>
                            </svg>
                            <label
                              :for="item.id"
                              class="checkbox-title d-inline-flex"
                              >{{ $t("label." + item.title) }}</label
                            >
                          </label>
                        </div>
                      </div>
                    </v-card-text>
                  </v-list>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="mt-1 d-flex justify-end">
                  <v-btn
                    :disabled="notSingleOfficer"
                    class="ma-2"
                    outlined
                    color="primary"
                    @click="saveChange"
                  >
                    <v-icon>{{ iconSave }}</v-icon>
                    {{ $t("button.save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <create-admin
        :showDialog="createAdminDialog"
        :karyawanExist="karyawanExist"
        @setAdminDialog="setAdminDialog"
      ></create-admin>
    </v-flex>
  </v-container>
</template>
<script>
import {
  mdiAlertCircleOutline,
  mdiContentSaveOutline,
  mdiDeleteForever,
  mdiPen,
  mdiPlusThick,
} from "@mdi/js";
import { mapActions, mapState } from "vuex";
import createAdmin from "@/components/commonComponents/CreateAdmin.vue";
// import Sortable from 'sortablejs/modular/sortable.core.esm.js';
export default {
  name: "setting-application",
  components: {
    createAdmin,
  },
  data: () => ({
    notSingleOfficer: false,
    iconSave: mdiContentSaveOutline,
    iconAlert: mdiAlertCircleOutline,
    iconPlus: mdiPlusThick,
    iconDelete: mdiDeleteForever,
    iconEdit: mdiPen,
    select: ["Vuetify"],
    combos: ["Programming", "Design", "Vue", "Vuetify"],
    items: undefined,
    createAdminDialog: false,
  }),
  created() {
    this.setAdministrators(undefined);
    this.getAdministrators(this.filterParams);
  },
  watch: {
    items2(val) {
      if (val.length !== 1) {
        this.notSingleOfficer = true;
      } else {
        this.notSingleOfficer = false;
      }
    },
  },
  computed: {
    ...mapState("adminpanel", [
      "administrators",
      "filterParams",
      "axiosLoading",
    ]),
    adminList() {
      if (!this.administrators) return undefined;
      return this.administrators.data;
    },
    karyawanExist() {
      if (!this.adminList) return undefined;
      return this.adminList.map(({ nik }) => nik);
    },
  },
  methods: {
    ...mapActions("adminpanel", ["getAdministrators", "setAdministrators","deleteAdministrator"]),
    saveChange() {
      console.log("save change");
    },
    changeMeInDb(e) {
      console.log(e.target.checked);
    },
    setAdminDialog(val) {
      this.createAdminDialog = val;
    },
    deleteAdmin(adminId, nik, nama) {
      if (["H008109002", "008522001", "H009208002"].includes(nik)) {
        return this.$dialog.notify.error(
          this.$t("error.cannot-delete-special-super-admin"),
          {
            position: "top-left",
            timeout: 5000,
          }
        );
      }
      //non Special super admin
     
      this.$dialog
        .confirm({
          text: this.$t("msgbox.are-you-sure-to-delete-administrator") +' ['+ nama +'] ?',
          title: this.$t("msgbox.delete-administrator"),
          persistent: true,
          icon: this.iconQuestion,
          color: "danger",
          actions: [
            { text: this.$t("button.cancel"), color: "primary", key: false },
            { text: this.$t("button.yes-delete"), color: "red", key: true },
          ],
        })
        .then((result) => {
          if (result != true) return;
          let formData = {
            _method: "DELETE",
          };
          this.deleteAdministrator({
            adminId : adminId,
            formData: formData
          })
          //do the process
        });
    },
  },
};
</script>
<style>
.left-40pcn {
  left: 40%;
  position: absolute;
}
</style>