<template>
  <v-dialog v-model="createAdminDialog" persistent max-width="850">
    <v-card v-if="!axiosLoading">
      <v-card-title class="headline">
        {{ $t("card.add-administrator") }}
      </v-card-title>
      <v-card-text>{{
        $t(
          "label.administator-access-is-limited-based-on-their-unit-or-department"
        )
      }}</v-card-text>
      <form>
        <v-card-text class="pt-0">
          <v-row>
            <v-card-text class="pb-0">
              <v-alert
                v-if="false"
                dense
                type="error"
                :icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left"
              >
                {{ $t("error.disposition-recipient-must-be-choosen") }}
              </v-alert>
            </v-card-text>
            <v-card-text class="text-center red--text" s>
              <v-autocomplete
                v-model="form.karyawan"
                :items="karyawans"
                :loading="isLoading"
                :search-input.sync="search"
                chips
                clearable
                hide-details
                hide-selected
                item-text="nama"
                item-value="nik"
                :label="$t('label.search-by-name-or-nik')"
                solo
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t("label.type-name-or-nik") }}
                      <strong>{{ $t("label.of-employee") }}</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template slot="selection" slot-scope="data">
                  {{ data.item.nama }}
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-avatar
                    color="indigo"
                    class="headline font-weight-light white--text"
                  >
                    <v-img :src="item.avatar_path"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.subtitle"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-card-text>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <label
                class="avoore-checkbox path d-block"
                v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
              >
                <input
                  type="checkbox"
                  class="d-inline-flex"
                  v-model="form.superadmin"
                  id="chk-superadmin"
                />
                <svg viewBox="0 0 21 21">
                  <path
                    d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                  ></path>
                </svg>
                <span for="chk-superadmin" class="checkbox-title"
                  >Super Administrator</span
                >
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </form>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>

        <v-btn outlined color="red darken-1" @click="createAdminDialog = false">
          <v-icon class="mr-2">{{ iconClose }}</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-btn outlined color="primary darken-1" @click="createAdmin">
          <v-icon class="mr-2">{{ iconSave }}</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="axiosLoading">
      <v-skeleton-loader
        class="mx-auto"
        max-width="100%"
        type="article, actions"
      ></v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  mdiAlertCircleOutline,
  mdiClose,
  mdiContentSave,
  mdiTransfer,
} from "@mdi/js";
import { http } from "@/http/http";
import { mapActions, mapState } from "vuex";
export default {
  name: "create-administrator",
  props: {
    showDialog: {
      type: Boolean,
    },
    karyawanExist: {
      type: [Array, Object],
    },
  },
  data: () => ({
    iconDisposition: mdiTransfer,
    iconClose: mdiClose,
    iconAlert: mdiAlertCircleOutline,
    iconSave: mdiContentSave,
    isLoading: false,
    karyawans: [],
    model: null,
    search: null,
    tab: null,
    form: {
      karyawan: undefined,
      superadmin: undefined,
    },
  }),
  watch: {
    model(val) {
      if (val != null) this.tab = 0;
      else this.tab = null;
    },
    search(val) {
      if (!val || val.length < 3) return;
      this.searchKaryawan();
    },
  },
  computed: {
    ...mapState("adminpanel", ["axiosLoading"]),
    createAdminDialog: {
      get() {
        return this.showDialog;
      },
      set(val) {
        this.$emit("setAdminDialog", val);
      },
    },
  },
  methods: {
    ...mapActions("adminpanel", ["addAdministrator"]),
    searchKaryawan() {
      this.isLoading = true;
      clearTimeout(this._timerId);

      this._timerId = setTimeout(() => {
        http
          .get(
            `/admin/user/karyawan?keyword=${this.search}&role_exist=${this.karyawanExist}`
          )
          .then((res) => {
            this.karyawans = res.data;
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => (this.isLoading = false));
      }, 1000); // 1 sec delay
    },
    createAdmin() {
      if (!this.form.karyawan) return;

      let newAdmin = this.filterAdmin();
      if (!newAdmin) return;

      let formData = {
        role: this.form.superadmin === true ? "1" : "3",
        nik: newAdmin.nik,
        unit: newAdmin.unit_id,
        bagian: newAdmin.bagian_id,
      };
      this.addAdministrator({ formData: formData });
      this.$nextTick(() => {
        this.karyawans = [];
        this.model = undefined;
        this.search = undefined;
        this.form = {
          karyawan: undefined,
          superadmin: undefined,
        };
        this.createAdminDialog = false;
      });
    },
    filterAdmin() {
      let that = this;
      let newAdmin = this.karyawans.filter(function (e) {
        return e.nik == that.form.karyawan;
      });
      return newAdmin[0];
    },
  },
};
</script>
<style>
.modal-alert {
  margin-bottom: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
</style>